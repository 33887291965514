/********************* General **********************/
/*****************************************************/
/*horizontal ruler*/
.divider,
.cms_divider,
.cms_item_divider,
.item_divider,
.separator,
#services_module.list .list_item .footer,
#submissionforms_module.cms_form .formBreak,
.module-forms .formBreak {
	height: 1px !important;
	margin-top: 1.5rem !important;
	margin-bottom: 1.5rem !important;
	background-color: hsla(0, 0%, 45%, 0.11) !important;
	border-top: 0 none !important;
	border-bottom: 0 none !important;
}

.collection > .node {
	border-top-color: hsla(0, 0%, 45%, 0.11);
	margin: 1.5rem 0 0 0;
	padding: 1.5rem 0 0 0;
}

.section_separator {
	border-bottom: 1px solid hsla(0, 0%, 45%, 0.11);
}

/* dates */
#alerts_module.list .title .date,
#events_module.cms_list .cms_date,
#feeds_module.cms_list .cms_list_item .cms_date h4,
#feeds_module.cms_entity .cms_date h4,
#jobs_module.cms_list .cms_list_item .cms_date h3,
#news_module.cms_list article time,
#news_module.cms_entity .cms_date h3,
#photoalbums_module.cms_list .cms_list_item .cms_number_of_images, /* this is not a date but styled as one */
.posts-node .posts-field.posts-field-posted p,
#video_module.cms_list .cms_list_item .cms_date,
#video_module.cms_entity .cms_date h3 {
	font-size: 0.97rem;
	line-height: 1.2rem;
	font-weight: 600;
}

/* ui form tags */
.ui-form-container {
	border: 0px;
}

.ui-form-container .ui-form-legend {
	background: none;
	margin-top: 1rem;
	margin-bottom: 0.5rem;
	color: #0b4b86;
	font-size: 1rem;
	line-height: 1.3rem;
	font-weight: 600;
	text-transform: none;
	padding: 0px;
	border-bottom: 0px;
}

.ui-form-container .ui-form-fields {
	border-bottom: 0px;
}

.ui-form-container .ui-form-fields .ui-form-field.required .ui-form-label em,
.ui-form-container .required_information em {
	color: #ee0000;
	font-family: inherit;
}

.ui-form-container .ui-form-fields .select2-container-multi .select2-choices {
	border: 1px solid #878787;
	border-radius: 0px;
	padding: 8px 12px;
	font-size: 0.87rem;
	line-height: 1.428571429;
	color: #333333;
	box-shadow: none;
}

.ui-form-container .ui-form-fields .select2-container-multi .select2-choices .select2-search-field .select2-input {
	padding: 0px;
}

.ui-form-container .ui-form-fields .select2-container .select2-choice {
	box-shadow: none;
	border-radius: 0px;
	background: none;
	border: 1px solid #ccc;
	height: 38px !important;
}

.ui-form-buttons {
	padding: 0px;
	width: 100%;
	text-align: center;
}

.ui-form-container .ui-form-fields .ui-form-error em {
	color: #ee0000;
	display: block;
	font-size: 0.9rem;
	font-style: normal;
	line-height: normal;
	font-weight: 600;
	margin: 0px 0px 0px 0px;
}

.required .ui-form-label {
	font-weight: normal;
}

.ui-form-label {
	color: inherit;
	font-family: inherit;
}

@media (max-width: 991px) {
	.ui-form-container .ui-form-fields {
		padding: 0px;
	}

	.ui-form-container .ui-form-label {
		position: static;
		padding: 0px;
		height: auto !important;
		display: block;
		width: 100%;
		left: 0px;
		text-align: left;
		top: auto;
		line-height: inherit;
	}

	.ui-form-container .ui-form-input {
		width: 100%;
	}

	.ui-form-container .ui-form-input input[type="text"],
	.ui-form-container .ui-form-input input[type="tel"],
	.ui-form-container .ui-form-input input[type="password"],
	.ui-form-container .ui-form-input input[type="email"],
	.ui-form-container .ui-form-input input[type="file"],
	.ui-form-container .ui-form-input select,
	.ui-form-container .ui-form-input textarea,
	.ui-form-container .ui-form-fields .select2-container,
	.ui-form-container .ui-form-fields .select2-container-multi,
	.ui-form-container .ui-form-fields .select2-container-multi .select2-choices .select2-search-field .select2-input {
		width: 100% !important;
		max-width: 100%;
	}
}

@media (max-width: 479px) {
	.ui-form-buttons button {
		margin-bottom: 10px;
	}
}

/* datepicker */
#ui-datepicker-div {
	font-size: 0.9rem;
}

/****************** Accounts ************************/
/***************************************************/
#account_module.form .form_container .login_field {
	width: 100%;
	max-width: 256px;
	margin-bottom: 15px;
}

#account_module.form .more_options ul {
	width: 100%;
	text-align: center;
	padding-left: 0;
}

#account_module.form .form_subheading {
	border-bottom: 1px solid rgba(140, 164, 189, 0.35);
	margin: 20px 0;
	padding-bottom: 7px;
	color: #0b4b86;
	font-size: 1.1rem;
	line-height: 1.3em;
}

/*edit address form*/
#account_module.form .ui-form-fields {
	margin-bottom: 10px;
}

#account_module.form .ui-form-label {
	margin-bottom: 3px;
}

#account_module.form .ui-form-label em {
	color: #ee0000;
	font-family: inherit;
}

@media (max-width: 991px) {
	#account_module.form .form_buttons {
		text-align: left;
	}

	#account_module.form tr.required .field {
		background: none;
		padding-left: 0;
		display: block;
		width: 100%;
	}

	#account_module.form .form_item .label,
	#account_module.form .form_item.required .label {
		display: inline-block;
		width: 100%;
		text-align: left;
		padding-bottom: 5px;
	}

	#account_module.form .form_item .label label,
	#account_module.form .form_item.required .label label {
		display: inline;
	}

	#account_module.form tr.required .label:after {
		content: url('/core/public/shared/assets/images/required.gif');
		padding-left: 10px;
	}

	#account_module.form .form_item .field {
		display: block;
		padding-left: 0;
	}

	#account_module.form .form_item .field .login_field {
		max-width: 100%;
	}

	#account_module.form .more_options ul {
		text-align: left;
	}
}

@media (max-width: 479px) {
	#account_module.form .ui-form-fields input[type="text"],
	#account_module.form .ui-form-fields select {
		width: 100% !important;
	}
}


/****************** Alerts ************************/
/*************************************************/
#alerts_module.list .title h2 {
	margin-top: 0px;
}

#alerts_module_subscribe_form.form .item_divider {
	border-top: 1px solid #e9ece3 !important;
	margin-bottom: 0 !important;
}

#alerts_module_subscribe_form .form_container h3 {
	padding-top: 0.75rem;
}

#alerts_module.form .field {
	padding-bottom: 15px;
}

#alerts_module.form .field small {
	display: block;
}

@media (max-width: 991px) {
	#alerts_module_subscribe_form.form .form_container {
		width: 100%;
	}

	#alerts_module.form .form_container .form_item .field,
	#alerts_module.form .form_container .form_item .field input[type="text"] {
		width: 100% !important;
		display: block;
		text-align: left;
		background: none;
	}

	#alerts_module.form .form_container .form_item .field {
		padding-left: 0px;
	}

	#alerts_module.form .form_container .form_item .label,
	#alerts_module.form .form_container .form_item.reqiuired .label {
		display: inline-block;
		text-align: left;
		width: auto;
	}

	#alerts_module.form .form_container .form_item.required .label {
		background: url('/core/public/shared/assets/images/required.gif') no-repeat right 11px;
		padding-right: 15px;
	}
}


/******************** Collections ******************/
/***************************************************/
.module-collections.controller-pages-collections .files-wrapper {
	margin-left: 5px;
}

/****************** Events *********************/
/***********************************************/
#events_module.cms_entity .cms_contact_name_label,
#events_module.cms_entity .cms_contact_phone_label,
#events_module.cms_entity .cms_location_label {
	font-weight: 600;
}

#fullcalendar-controls #categories .category-wrapper .category {
	top: 0px;
}

#events_module.cms_list .cms_list_item .cms_title {
	width: 100%;
}

#events_module.cms_list .cms_list_item .cms_title h3 {
	margin-top: 0px;
}

#events_module.cms_list .cms_list_item .event-category-label {
	display: inline-block;
	margin-right: 4px;
}

#events_module.cms_list .cms_list_item .location-label-events-module {
	font-weight: 600;
}

#events_module.cms_form .cms_label,
#events_module.cms_form .cms_field,
#events_module.cms_form .cms_required,
#events_module.cms_form .cms_non_required,
#events_module.cms_form .cms_td_answers {
	background-color: transparent;
	vertical-align: top;
}

#events_module.cms_form .cms_required {
	background-position: center 10px;
}

#events_module.cms_form h3 {
	margin-bottom: 0px;
}

#events_module.cms_form .cms_divider {
	margin-top: 5px !important;
	padding-top: 1px !important;
}

@media (max-width: 991px) {
	#events_module.cms_form .cms_field input[type="text"],
	#events_module.cms_form .cms_field input[type="password"],
	#events_module.cms_form .cms_field select,
	#events_module.cms_form .cms_field textarea,
	#events_module.cms_form .cms_field {
		display: block;
		clear: both;
		width: 100% !important;
		max-width: 100%;
	}

	#events_module.cms_form .cms_label {
		display: inline;
		text-align: left;
		padding-left: 0px;
		width: auto;
		float: left;
	}

	#events_module.cms_form .cms_required,
	#events_module.cms_form .cms_non_required {
		width: 20px;
		display: inline;
		text-align: left;
		padding-left: 0px;
		float: left;
	}
}



/****************** Files *********************/
/***********************************************/
.collection--files .node--file,
.collection--categories .node--category,
.collection.collection--files {
	margin: 1rem 0px 0px 0px;
	padding: 1rem 0px 0px 0px;
	border-top: 1px solid hsla(0, 0%, 45%, 0.11);
}

.collection--files .node--file p,
.collection--categories .node--category p,
.collection.collection--files p {
	margin-bottom: 0px;
	margin-top: 0px;
}

.collection.collection--files .bookmark.create {
	background-color: transparent;
	color: #6b7571;
	font-weight: 400;
}

/* sidebar file filtering */
.inside-page-nested-right-column .box.filter .filter-row .select2-container,
.inside-page-nested-right-column .box.filter .filter-row #filter-keywords {
	max-width: 100%;
	width: 100% !important;
}

.inside-page-nested-right-column .box.filter .filter-row.ui-form-buttons {
	padding: 0px;
}

.inside-page-nested-right-columnn .box.filter .filter-row.ui-form-buttons .ui-form-field {
	padding: 6px;
	text-align: left;
}

.inside-page-nested-right-column .box.filter .filter-row .select2-container-multi .select2-choices,
.inside-page-nested-right-column .box.filter .filter-row .select2-container .select2-choice {
	border-radius: 0px;
	box-shadow: none;
	border: 1px solid #878787;
	background: none;
	padding: 8px 12px;
	height: auto;
}

.inside-page-nested-right-column .box.filter .filter-row .select2-container .select2-choice > .select2-chosen {
	line-height: 1.2rem;
}

.inside-page-nested-right-column .box.filter .filter-row .select2-container-multi .select2-choices .select2-search-field .select2-input {
	margin: 0;
	padding: 0px;
}

/****************** FAQs *********************/
/***********************************************/
#faqs_module .cms_title h3 {
	margin-top: 0px;
}

#faqs_module .cms_list_item ul {
	padding-left: 0px;
	margin: 0px;
}

#faqs_module .cms_list_item ul li {
	padding-bottom: 7px;
}

#faqs_module.cms_list .cms_list_subitem {
	padding-bottom: 5px;
}

/****************** Forums *********************/
/***********************************************/
#forums_module #posts table,
#forums_module #boards table,
#forums_module #topics table,
#forums_module #posts .post_footer {
	background: none;
	border: 0px;
}

#forums_module #boards td,
#forums_module #topics td,
#forums_module #posts td {
	border-color: hsla(0, 0%, 45%, 0.11);
	padding-top: 1.5rem;
	padding-bottom: 1.5rem;
}

#forums_module #boards tr td:last-child,
#forums_module #topics tr td:last-child,
#forums_module #posts tr td:last-child,
#forums_module #topics th {
	border-color: hsla(0, 0%, 45%, 0.11);
}

#forums_module h2 {
	color: #0b4b86;
	margin-top: 0px;
}

#forums_module #posts table td.actions a {
	height: auto;
}

#forums_module #topics tfoot tr th {
	padding-top: 1.5rem;
}

#forums_module.form.advanced-search .form_buttons {
	text-align: left;
}

#forums_module .pagination,
#forums_module .header-bottom,
#forums_module .header-top .header-right {
	font-size: 0.9rem;
}

#forums_module #posts table td.actions a {
	color: #000000;
	font-family: inherit;
}

@media (max-width: 991px) {
	#forums_module #topics tr td.poster_info,
	#forums_module #posts tr td.poster_info {
		padding-top: 1.5rem;
	}

	#forums_module #topics tr.moderator_actions td {
		padding-top: 0px;
		padding-bottom: 0px;
	}

	#forums_module #posts tfoot tr td {
		padding-top: 1.5rem;
	}

	#forums_module tr td small {
		font-size: 80%;
	}
}

/******************** Glossary **********************/
/***************************************************/

/****************** Jobs ***********************/
/***********************************************/
#jobs_module.cms_list .cms_list_item {
	margin-top: 0px;
}

#jobs_module.cms_list .cms_list_item .cms_title h3,
#jobs_module.cms_list .cms_list_item .cms_date h3 {
	margin-top: 0px;
}

#jobs_module.cms_list .cms_list_item .cms_footer {
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
	border-color: hsla(0, 0%, 45%, 0.11);
}

#jobs_module.cms_entity .cms_content dt {
	font-weight: 600;
}

#jobs_module.cms_form .cms_label,
#jobs_module.cms_form .cms_field,
#jobs_module.cms_form .cms_required {
	background: none;
	font-weight: normal !important;
	vertical-align: top;
	padding-top: 3px;
}

#jobs_module.cms_form .cms_divider {
	height: 0px !important;
}

#jobs_module.cms_form .cms_form_button {
	padding: 0.375rem 1rem;
}

#jobs_module.cms_form .checkbox {
	vertical-align: top;
}

/* sidebar job search filtering */
.right-sidebar-colum .box #frmDepartments {
	margin-top: 0.75rem;
}

.right-sidebar-colum .box #frmDepartments #Department {
	width: calc(100% - 55px);
}

.right-sidebar-colum.box #frmDepartments button[type="submit"].primary {
	padding: 9px 10px !important;
	display: inline;
	float: right;
}

@media (max-width: 991px) {
	#jobs_module.cms_form .cms_field input[type="text"],
	#jobs_module.cms_form .cms_field {
		display: block;
		clear: both;
		width: 100% !important;
		max-width: 100%;
	}

	#jobs_module.cms_form .cms_label,
	#jobs_module.cms_form .cms_required {
		display: inline;
		text-align: left;
		padding-left: 0px;
		width: auto;
		float: left;
		padding-top: 7px;
	}

	#jobs_module.cms_form .cms_required {
		width: 20px;
	}
}

/************* Legilsative Districts ************/
/***********************************************/

/******************** Links ************************/
/***************************************************/

/****************** Locations *******************/
/***********************************************/
#locations_module.list .locations_list_title,
#locations_module.list .location_types_title {
	border-color: hsla(0, 0%, 45%, 0.11);
}

/****************** Mailing Lists ************************/
/***************************************************/
#mailinglists-module.form .form_subheading {
	border-bottom: 1px solid hsla(0, 0%, 45%, 0.11);
	margin: 12px 0 20px;
}

/****************** News ***********************/
/***********************************************/
#news_module.cms_entity .cms_date h3 {
	margin-top: 0px;
}

#news_module.cms_list .cms_categories_row .image {
	margin-bottom: 10px;
}

.news_module.cms_category_list .cms_category_header_image {
	margin-bottom: 10px;
}

.box.archive .archive_links {
	overflow: hidden;
}

@media (max-width: 767px) {
	#news_module.cms_entity .cms_content .image {
		display: block;
		float: none;
		margin-left: 0px;
		height: auto;
		margin-bottom: 15px;
		margin-top: 15px;
	}
}

/****************** Newsletters ************************/
/***************************************************/
#newsletters_module.entity ul {
	margin: 0px;
	padding: 0px;
	list-style-type: none;
}

#newsletters_module.entity h3,
#newsletters_module.entity h4 {
	margin-top: 0px;
}

#newsletters_module.form .form_item .field {
	padding-bottom: 15px;
}

@media (max-width: 991px) {
	#newsletters_module.form .form_item .label,
	#newsletters_module.form .form_item.required .label {
		width: auto;
		display: inline-block;
		text-align: left;
		padding-bottom: 5px;
	}

	#newsletters_module.form .form_item.required .label {
		background-image: url('/core/public/shared/assets/images/required.gif');
		background-repeat: no-repeat;
		background-position: right center;
		padding-right: 20px;
	}

	#newsletters_module.form .form_item .label label {
		display: inline-block;
	}

	#newsletters_module.form .form_item .field {
		width: 100%;
		display: block;
		padding-left: 0;
	}

	#newsletters_module.form .form_item.required .field {
		background: transparent none;
	}

	#newsletters_module.form .form_item .field table tr td {
		vertical-align: top;
	}

	#newsletters_module.form .form_buttons {
		text-align: left;
	}

	#newsletters_module.form .form_item input[type="text"] {
		width: 100% !important;
	}
}


/**************** Online Payments ***************/
/***********************************************/

/****************** Partners ********************/
/***********************************************/
#partners_module.cms_list .cms_list_item .cms_title h3 {
	margin-top: 0rem;
}

#partners_module.cms_list .cms_list_item table td.image {
	padding-right: 25px;
}

#partners_module.cms_list .cms_list_item table td.image a img {
	max-width: 150px;
}

#partners_module.cms_entity .image {
	float: none;
	margin-right: 0px;
}

@media (max-width: 991px) {
	#partners_module.cms_list .cms_list_item table td {
		display: block;
		width: 100%;
	}

	#partners_module.cms_list .cms_list_item table td.image {
		text-align: left;
		width: 100%;
		margin-bottom: 10px;
		padding-right: 0px;
	}
}

/****************** Photo Albums ****************/
/***********************************************/
#photoalbums_module.cms_list .cms_list_item table td.cms_image {
	padding-right: 15px;
}

#photoalbums_module.cms_list .cms_list_item table td.cms_image a img {
	max-width: 150px;
}

@media (max-width: 991px) {
	#photoalbums_module.cms_list .cms_list_item table td {
		display: block;
		width: 100%;
	}

	#photoalbums_module.cms_list .cms_list_item table td.cms_image {
		text-align: left;
		width: 100%;
		margin-bottom: 10px;
		padding-right: 0px;
	}
}

/******************* Polls *********************/
/***********************************************/
#polls #pollResult .line {
	background-color: #0b4b86;
	height: 4px;
}


/******************* Posts *********************/
/***********************************************/
.posts-collection .posts-node .posts-field h2 {
	margin-top: 0px;
}

.posts-node #post-comments .post-comments-title {
	background: #0b4b86;
}

.posts-node .posts-comments .ui-form-fieldset legend.ui-form-legend {
	font-size: 1rem;
}

.posts-node #post-comments .post-comment .post-comment-author {
	font-size: 0.8rem;
}

.posts-node #post-comments .post-comment {
	margin-top: 1rem;
	padding: 0px 0px 1rem 0px;
	border-bottom: 1px solid hsla(0, 0%, 45%, 0.11);
}

.posts-node .posts-comments #post-add-comment .ui-form-fields .ui-form-textarea textarea,
.posts-node .posts-comments #post-add-comment .ui-form-fields .ui-form-input input[type="text"] {
	width: 100%;
}

#posts_module.form .form_item .ui-form-label,
#posts_module.form .form_item .required .ui-form-label {
	width: 100%;
	text-align: left;
}

.posts-node .required_information {
	color: #ee0000;
	font-weight: 400;
}


/* sidebar posts */
.inside-page-nested-right-column .box.posts-blocks.posts-blocks-authors .posts-blocks-posts,
.inside-page-nested-right-column .box.posts-blocks.posts-blocks-authors .posts-blocks-author,
.inside-page-nested-right-column .box.posts-blocks.posts-blocks-categories .posts-blocks-category,
.inside-page-nested-right-column .box.posts-blocks.posts-blocks-popular-posts .posts-blocks-popular-post {
	padding-bottom: 7px;
}

.inside-page-nested-right-column .box.posts-blocks.posts-blocks-authors .posts-blocks-posts {
	padding-left: 7px;
}

.inside-page-nested-right-column .posts-blocks.posts-blocks-searches .posts-blocks-search-form input[type="text"].posts-blocks-search-field {
	height: 37px;
}

.inside-page-nested-right-column .posts-blocks.posts-blocks-searches .posts-blocks-search-form .posts-blocks-search-button {
	padding: 8px 11px 6px !important;
}



/********** Products (not e-commerce) **********/
/***********************************************/
#products_module.cms_list .cms_list_item .cms_title h3 {
	margin-top: 0px;
}

#products_module.cms_entity .cms_content .image {
	margin-right: 15px;
	margin-left: 0px;
}

@media (max-width: 991px) {
	#products_module.cms_list .cms_list_item.left,
	#products_module.cms_list .cms_list_item.right {
		width: 100%;
		display: block;
		padding-left: 0px;
		padding-right: 0px;
	}

	#products_module.cms_list .cms_list_item .tile_description .image {
		float: none;
		display: block;
		padding-left: 0px;
	}

	#products_module.cms_list .cms_list_item .cms_title {
		padding: 0px;
	}

	#products_module.cms_list .cms_list_item.left tr td,
	#products_module.cms_list .cms_list_item.right tr td {
		border-bottom: 1px solid hsla(0, 0%, 45%, 0.11);
		padding-top: 1.5rem !important;
		padding-bottom: 1.5rem !important;
	}

	#products_module.cms_list .cms_item_divider {
		margin-top: 0px !important;
		margin-bottom: 0px !important;
		background: none !important;
	}
}

@media (max-width: 767px) {
	#products_module.cms_entity .cms_content .image {
		display: inline-block;
		float: none;
		margin: 0px 0px 10px 0px;
	}
}

/******************** Rates ********************/
/***********************************************/

/***************** Resources *******************/
/***********************************************/

/****************** RSS Feeds *********************/
/*************************************************/
#feeds_module.cms_list .cms_title h3,
#feeds_module.cms_list .cms_date h4 {
	margin-top: 0px;
}

#feeds_module.cms_list .abstract {
	padding: 0px;
}

/***************** Services *******************/
/***********************************************/
#services_module.list .list_item .title h3 {
	margin-top: 0px;
}

#services_module.entity .image {
	float: none;
	margin: 0px 0px 10px 0px;
}


/*************** Staff Directory ***************/
/***********************************************/
#staffdirectory_module.cms_list .cms_list_item .image {
	padding-right: 15px;
	width: 150px;
}

#staffdirectory_module.cms_list .cms_list_item .image a img {
	max-width: 140px;
}

@media (max-width: 991px) {
	#staffdirectory_module.cms_list .cms_list_item .image {
		text-align: left;
		width: 100%;
		margin-bottom: 10px;
		padding-right: 0px;
	}

	#staffdirectory_module.cms_entity .image {
		float: none;
		margin-right: 0px;
		display: block;
	}
}


/*************** Submission Forms ***************/
/***********************************************/
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_first span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_last span {
	padding-top: 2px;
	font-size: 0.9rem;
}

#submissionforms_module.cms_form .cms_label div {
	display: inline;
}

#submissionforms_module.cms_form .sigPad input {
	padding: 8px 12px;
}

#submissionforms_module.cms_form .cms_field {
	padding-bottom: 15px;
}

#submissionforms_module.cms_form .checkbox_wrapper .item label,
#submissionforms_module.cms_form .radio_wrapper .item label {
	margin: 0px;
	font-size: 0.9rem;
	line-height: normal;
}

#submissionforms_module.cms_form .cms_hint,
#submissionforms_module.cms_form .cms_date_hint,
#submissionforms_module.cms_form .agreement_hint,
#submissionforms_module.cms_form .cms_field_note,
#submissionforms_module.cms_form input[type="file"],
#submissionforms_module.homepage_contentbox .cms_date_hint,
#submissionforms_module.homepage_contentbox .agreement_hint {
	font-size: 0.8rem;
	color: inherit;
}

/*form with payment*/
#submissionforms_module.cms_form .cms_field.currency::before,
.responsive #submissionforms_module.cms_form .cms_field.currency::before {
	top: 27%;
}

#submissionforms_module.cms_form .cms_field.currency span,
.responsive #submissionforms_module.cms_form .cms_field.currency span {
	margin-left: 20px !important;
}

#submissionforms_module.cms_form #form-payment-info .ui-form-field-subtitle .ui-form-label label,
#submissionforms_module.cms_form #form-payment-info .ui-form-field-subtitle .ui-form-label span {
	font-size: 100%;
	font-weight: 400;
}

#submissionforms_module.cms_form #form-payment-info .s-payment-method-label {
	display: inline;
}

#submissionforms_module.cms_form #form-payment-info #payment-info .ui-form-panel {
	padding-top: 0 !important;
}

#submissionforms_module.cms_form #form-payment-info .ui-form-fieldset:not(#payment-info) .ui-form-panel {
	padding-bottom: 0 !important;
}

#submissionforms_module.cms_form #form-payment-info .ui-form-expiration-date-fields > div:first-of-type {
	margin-bottom: 10px;
}

#submissionforms_module.cms_form #form-payment-info .ui-form-text-phone-extension {
	margin-top: 10px;
	margin-left: 0;
}

#submissionforms_module.cms_form .signature_name {
	display: block;
}

#submissionforms_module.cms_form .sigWrapper.current {
	display: block !important;
}

/*confirmation page*/
.module-forms.confirmation .value-cell img.signature-image {
	max-width: 100%;
}

.module-forms.confirmation .label-cell {
	font-weight: normal;
}

.module-forms .ui-form-field-payment-information,
.module-forms .ui-data-addresses h3 {
	font-size: 1.527rem;
	margin-top: 0px;
}

#submissionforms_module.cms_form .cms_required em {
	color: #ee0000;
	font-family: inherit;
}

#submissionforms_module.cms_form .required_information em {
	color: #ee0000;
	font-family: inherit;
}

.cms_steps_wrapper {
	color: #0b4b86;
	font-family: inherit;
}

@media (max-width: 991px) {
	.module-forms.confirmation td {
		display: block;
		width: 100%;
	}

	.module-forms.confirmation .label-cell {
		padding-bottom: 0;
	}

	.module-forms.confirmation .value-cell {
		padding-top: 0;
	}

	#submissionforms_module.cms_form #form-payment-info .ui-form-text-phone-extension {
		width: 100%;
	}
}

@media (max-width: 767px) {
	#submissionforms_module.cms_form .cms_field.currency::before,
	.responsive #submissionforms_module.cms_form .cms_field.currency::before {
		top: 35%;
		left: 5px;
	}
}


/*************** Success Stories ***************/
/***********************************************/
#stories_module.cms_list .cms_list_item .cms_title h3 {
	margin-top: 0px;
}

#stories_module.cms_list .cms_list_item .image {
	max-width: 200px;
}

@media (max-width: 991px) {
	#stories_module.cms_list .cms_list_item .image {
		padding: 0px 0px 10px 0px;
		display: block;
		float: none;
	}
}

/**************** Testimonials ******************/
/***********************************************/
#testimonials_module.cms_list .cms_list_item .cms_bottom,
#testimonials_module.cms_list .cms_list_item .cms_content {
	display: inline-block;
	width: 100%;
}

#testimonials_module.cms_list .cms_list_item .cms_author_name {
	font-weight: 500;
	font-size: 0.9rem;
}


/******************* Videos ********************/
/***********************************************/
#video_module.cms_list .cms_list_item {
	border-bottom: 1px solid hsla(0, 0%, 45%, 0.11);
	padding-bottom: 1.5rem;
	margin-bottom: 1.5rem;
}


/**************** White Papers ******************/
/***********************************************/
#whitepapers_module.cms_list .cms_list_item .cms_title h3 {
	margin-top: 0px;
}

@media (max-width: 991px) {
	#whitepapers_module.cms_list .cms_list_item .image {
		padding: 0px 0px 10px 0px;
		display: block;
		float: none;
	}
}

/**************** Classifieds ******************/
/***********************************************/
.classifieds-collection .classifieds-node .classifieds-field h2 {
	margin-top: 0px;
}

.classifieds-collection .classifieds-node .classifieds-field:last-child p {
	margin-bottom: 0px;
}

/******************** Ideas *********************/
/***********************************************/
.ideas-collection .ideas-node .ideas-field-title h2 {
	margin-top: 0px;
}

.ideas-collection .ideas-node .ideas-field:last-child p {
	margin-bottom: 0px;
}

/******************** Kudos *********************/
/***********************************************/
.kudos-collection .kudos-node .kudos-field-title h2 {
	margin-top: 0px;
}

.kudos-collection .kudos-node .kudos-field:last-child p {
	margin-bottom: 0px;
}

.kudos-node .kudos-comments-title {
	background: #0b4b86;
	font-weight: 500;
}

.kudos-node .kudos-comment-author {
	font-size: 0.8rem;
}

.kudos-node .kudos-comments .ui-form-fieldset legend.ui-form-legend {
	font-weight: normal;
}

.kudos-node #kudos-comments .kudos-comment-form-required-info textarea {
	width: 100%;
}

.kudos-node .kudos-comment {
	border-bottom: 1px dotted hsla(0, 0%, 45%, 0.11);
	padding-top: 10px;
	padding-bottom: 10px;
}


/****************** Resources *****************/
/***********************************************/
.resource-collection .resource-option {
	background: none;
	padding: 0px;
	text-align: right;
}

.resource-collection .resource-option .select2-container--default {
	max-width: 100%;
}

.resource-collection .resource-option .select2-container--default .select2-selection--single {
	background-color: #fff;
	border: 1px solid #878787;
	border-radius: 0px;
}

.module-reservations .reservations-module .ui-form-input input[type="text"],
.module-reservations .reservations-module .ui-form-input select,
.module-reservations .reservations-module .ui-form-input textarea {
	max-width: 100%;
	padding: 8px 12px;
	vertical-align: middle;
	background-color: #ffffff;
	margin-left: 0px !important;
	border: 1px solid #878787;
}

.module-reservations .reservations-module .ui-form-label {
	margin-left: 0px !important;
}

.module-reservations .reservations-module .ui-form-legend-wrapper {
	width: 100%;
}

.module-reservations .reservations-module .ui-form-legend-wrapper .ui-form-legend {
	width: 100%;
	color: #ffffff;
	background: #0b4b86;
	display: inline-block;
	height: auto;
	padding-top: 4px;
	padding-bottom: 4px;
}

.resource-collection .resource-option .select2-hidden-accessible {
	left: 0;
}

@media (max-width: 991px) {
	.module-reservations .reservations-module .ui-form-input input[type="text"].hasDatepicker {
		width: calc(100% - 20px) !important;
	}

	.module-reservations .reservations-module .ui-datepicker-trigger {
		margin-top: 13px;
	}
}

/****************** Medical ************************/
/***************************************************/
/* accordion search */
.physician-search-accordion .physician-search-wrap .physician-search-field .top-search-submit {
	height: auto;
	margin-top: 2.8rem;
}

.physician-search-accordion .physician-search-wrap .physician-search-field .form-reset {
	font-size: 0.9rem;
	margin-top: 3.1rem;
}

.physician-search-accordion .physician-search-wrap .physician-search-field .bottom-search-submit {
	background-color: #0c8563;
	margin: 0;
}

.physician-search-accordion .physician-search-wrap .physician-search-field .bottom-search-submit:hover {
	background-color: #0b4b86;
	color: rgba(255, 255, 255, 0);
	font-weight: 400;
}

/* physicians */
#physicians-module.physicians-collection .md-custom-heading,
#physicians-module.node .md-custom-heading {
	font-weight: 600;
}

#physicians-module.physicians-collection .node.physician-node {
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
	border-bottom: 1px solid hsla(0, 0%, 45%, 0.11);
}

#physicians-module .field.field--phone .md-custom-heading.md-big-heading {
	font-size: 1.2rem;
}

#physicians-module.node .physician-profile-section,
#physicians-module .md-row.doctor-profile-section {
	border-top: 1px solid hsla(0, 0%, 45%, 0.11) !important;
	margin-top: 1.5rem !important;
	padding-top: 1.5rem !important;
}

#physicians-module.advanced-search .physicians-advanced-search-form-wrap .physicians-search-form-rows-wrap {
	background: #ebebeb;
	color: #333333;
	font-family: inherit;
}

#physicians-module.advanced-search .physicians-advanced-search-form-wrap .physicians-search-form-rows-wrap .select2-container--default .select2-selection--single {
	border: 1px solid #ccc;
	margin-bottom: 5px;
	margin-top: 5px;
}

#physicians-module.node .md-custom-heading.patient-rating a,
#physicians-module.node .md-custom-heading.patient-rating a:hover {
	color: #727272;
	font-family: inherit;
}


/*clinics*/
#clinics-module.main-content-column-nested .md-custom-heading {
	font-weight: 600;
}

#clinics-module.main-content-column-nested .md-section-h2 {
	margin-bottom: 5px;
}

/*greeting cards*/
#greetingcards-module .GreetingCardGeneralInfo .ui-form-fieldset .ui-form-field em {
	color: #ee0000 !important;
	font-family: inherit;
}

@media (min-width: 991px) {
	#greetingcards-module .greeting-card-form-preview-wrapper .GreetingCardGeneralInfo {
		width: 70%;
	}

	#greetingcards-module .greeting-card-form-preview-wrapper #greeting-cards-preview {
		width: 30%;
	}
}

@media (max-width: 991px) {
	#greetingcards-module .greeting-card-form-preview-wrapper .ui-form-label {
		margin-bottom: 5px;
	}

	#greetingcards-module .greeting-card-form-preview-wrapper .GreetingCardGeneralInfo .ui-form-fieldset .ui-form-field input,
	#greetingcards-module .greeting-card-form-preview-wrapper .GreetingCardGeneralInfo .ui-form-fieldset .ui-form-field textarea {
		max-width: 100%;
	}

	#greetingcards-module .greeting-card-form-preview-wrapper .GreetingCardGeneralInfo .ui-form-fieldset .ui-form-field #field-roomNumber {
		width: 100% !important;
	}
}

/* babies*/
#babies-module.collection .baby-node {
	border-color: hsla(0, 0%, 45%, 0.11);
}

/****************** eCommerce ************************/
/***************************************************/
/* listing */
#products_module.responsive.cms_list .items.list-view .item-wrapper .item {
	margin: 0px;
}

#products_module.responsive.cms_list .items .item-wrapper .item .item-description .cms_title h3 {
	line-height: inherit;
}

#products_module.responsive.cms_list .items .item-wrapper .item .item-description p {
	font-size: 0.9rem;
}

#products_module.responsive.cms_list .pagination-wrapper.pagination-wrapper-top {
	margin-bottom: 10px;
}

#products_module.responsive.cms_list .pagination-wrapper .fly-out-menu-container .fly-out-menu-button {
	border: 1px solid #ccc;
}

#products_module.responsive.cms_list .items.list-view .item-wrapper {
	padding-bottom: 1.5rem;
	margin-bottom: 1.5rem;
	border-bottom: 1px solid hsla(0, 0%, 45%, 0.11);
}

#products_module.responsive.cms_list .items .item-wrapper .item .item-price {
	font-weight: 600;
}

@media (max-width: 991px) {
	#products_module.responsive.cms_list .items.list-view .item-wrapper .item .item-description {
		margin-left: 0;
		width: 100%;
	}

	#products_module.responsive.cms_list .items.list-view .item-wrapper .item .item-image {
		float: none;
		margin-bottom: 10px;
	}

	#products_module.responsive.cms_list .items.list-view .item-wrapper {
		margin-bottom: 1.7rem;
		padding-bottom: 1.2rem;
	}
}

/* details view */
#products_module.responsive.cms_entity .mini-slide-image-outer-wrapper.selected {
	border: 1px solid #0c8563;
}

#products_module.responsive.cms_entity .product-details-info-wrapper .product-details-info {
	padding: 15px;
}

#products_module.responsive.cms_entity .product-details-wrapper .product-sku {
	color: #000000;
	font-family: inherit;
}

#products_module.responsive.cms_entity .product-details-info-wrapper .product-details-info .product-prices {
	font-weight: 600;
	color: #333;
}

#products_module.responsive.cms_entity .add_to_cart_form .contentbox_item br {
	display: none;
}

#products_module.responsive.cms_entity .add_to_cart_form .quantity_row .product-quantity-label {
	width: 85px;
}

#products_module.responsive.cms_entity .product-details-info-wrapper .product-details-info .add_to_cart_form .denomination-validation-message,
#products_module.responsive.cms_entity .add_to_cart_form .minimum_quantity_label,
#products_module.responsive.cms_entity .add_to_cart_form .maximum_quantity_label {
	font-weight: 400;
	color: #ee0000;
}

#products_module.responsive.cms_entity .availability_box .available {
	font-weight: 600;
	color: #6b7571;
	display: block;
}

#products_module.responsive.cms_entity .add_to_cart_form input[type="text"].hasDatepicker {
	width: calc(100% - 30px);
}

#products_module.responsive.cms_entity .add_to_cart_form .attribute-type-date img {
	margin-left: 5px;
	margin-top: -7px;
}


#products_module.responsive.cms_entity .related-items .related-items-title,
#products_module.responsive.cms_entity .recently-viewed-items .recently-viewed-items-title {
	margin: 25px 0px 0px 0px;
	font-size: 1.3rem;
	font-weight: 400;
	display: inline-block;
}

#products_module.responsive.cms_entity .contentbox_item .accepted-formats {
	word-break: break-all;
	line-height: 1rem;
}


#products_module.responsive.cms_entity .related-items-wrapper .item-wrapper .item-price {
	font-weight: 600;
}

#products_module.responsive.cms_entity .tab-item-content .product-review .product-review-author {
	font-size: 0.875rem;
	font-weight: 600;
}

#products_module.responsive.cms_entity .tab-item-content .product-review {
	border-bottom: 1px solid hsla(0, 0%, 45%, 0.11);
	margin-bottom: 1.5rem;
	margin-top: 0;
	padding-bottom: 1.5rem;
	padding-top: 0;
}

#products_module.responsive.cms_entity .tab-item-content .product-review:first-of-type {
	margin-top: 1rem;
}

#products_module.responsive.cms_entity .tab-item-content .product-review:last-of-type {
	border-bottom: 0 none;
	padding-bottom: 0;
}

#products_module.responsive.cms_entity .tab-item-content .product-question-have-a-question {
	padding-top: 0;
	border-top: 0 none;
	font-weight: normal;
}

#products_module.responsive.cms_entity .tab-item-content .product-question p {
	font-weight: normal;
}

#products_module.responsive.cms_entity .tab-item-content .product-question-answer {
	margin: 5px 0 0;
}

#products_module.responsive.cms_entity .tab-item-content .product-question-answer p:last-of-type {
	margin-bottom: 0;
}

#products_module.responsive.cms_entity .tab-item-content .product-questions {
	margin: 0 0 1.5rem 0;
	padding: 0 0 1.5rem 0;
	border-bottom: 1px solid hsla(0, 0%, 45%, 0.11);
}

#products_module.responsive.cms_entity .tab-item-content .product-questions:last-of-type {
	padding-bottom: 0;
	border-bottom: 0 none;
}

#products_module.responsive.cms_form .preferred-contact-method-label.ui-form-label {
	font-weight: normal;
}

#products_module.responsive.cms_entity .product-attribute-file-upload input {
	font-size: 0.8rem;
}

.products_module .item-sale-price,
#products_module.responsive .item-sale-price {
	color: #ee0000 !important;
	font-family: inherit;
}

#products_module.responsive.cms_form .required-fields-information em {
	color: #ee0000;
	font-family: inherit;
}

#products_module.responsive.cms_form .ui-form-label {
	height: auto;
}

@media (max-width: 991px) {
	#products_module.responsive.cms_form .ui-form-field {
		width: 100% !important;
	}
}

@media (max-width: 720px) and (min-width: 481px) {
	#products_module.responsive.cms_entity .add_to_cart_form .cart-estimate-button {
		margin-right: 10px;
		margin-left: 10px;
	}
}

/* cart */
#s-cart .s-sections .s-label,
#s-cart #s-subtotals .s-row .s-label,
.cart-grid .s-row.s-header-row,
#order-pad .s-row.s-header-row,
#order-review .s-row.s-header-row,
#s-cart .s-row.s-header-row,
#s-wishlist .s-row.s-header-row,
#s-favorites .s-row.s-header-row,
#s-cart .s-row .s-descriptions span.s-sku,
#s-wishlist .s-row .s-descriptions span.s-sku,
#s-favorites .s-row .s-descriptions span.s-sku,
#s-checkout .s-sections .s-label,
#s-checkout #s-subtotals .s-row .s-label,
#s-order-confirmation .s-sections .s-label,
#s-order-confirmation #s-subtotals .s-row .s-label,
#s-checkout .s-row .s-descriptions span.s-sku {
	font-weight: 400;
}

#products_module.responsive.cms_form .required-fields-information em,
#products_module.responsive.cms_form label em,
#products_module.responsive.cms_form .ui-form-label em,
#s-customer label em,
#s-checkout label em,
#s-checkout span em {
	color: #ee0000;
	font-family: inherit;
}

#s-customer #s-sign-in .s-signin-options label {
	font-size: 0.9rem;
}

#s-checkout .ui-form-buttons.ui-form-field-shipping,
.ui-form-buttons.ui-form-field-payment,
#s-checkout .ui-form-buttons.ui-form-field-place-order {
	padding: 0 !important;
}

#s-order-confirmation h2,
#s-order-confirmation h3 {
	margin-top: 0px;
}

#s-order-confirmation .underline-section {
	border-top: 1px solid hsla(0, 0%, 45%, 0.11);
}

#s-checkout fieldset .ui-form-legend .ui-form-legend-text {
	font-weight: 500;
	font-size: 1.4rem;
}

@media (max-width: 991px) {
	#s-checkout .ui-form-text-phone-extension {
		margin-left: 0px;
		width: 100%;
	}

	#s-order-confirmation .ui-form-shipping-address-parent .columns,
	#s-order-confirmation .ui-data-payment .ui-data-payments,
	#s-order-confirmation .ui-data-payment .ui-data-addresses {
		width: 100%;
		float: none;
	}
}

@media (max-width: 767px) {
	#s-cart .s-row .s-quantity-field,
	#s-wishlist .s-row .s-quantity-field,
	#s-favorites .s-row .s-quantity-field {
		height: 40px;
	}

	#s-customer #s-sign-in .ui-form-input,
	#s-customer #s-sign-in .ui-form-input input[type="text"],
	#s-customer #s-sign-in .ui-form-input input[type="password"] {
		width: 100%;
		max-width: 100%;
	}
}


/******************** Content Boxes ********************/
/*****************************************************/
/* alerts */
#alerts_module.homepage_contentbox ul {
	list-style-type: none;
	text-align: left;
	margin: 0px;
	padding: 0px;
}

#alerts_module.homepage_contentbox.list_alerts .alerts a:link,
#alerts_module.homepage_contentbox.list_alerts .alerts a:active,
#alerts_module.homepage_contentbox.list_alerts .alerts a:visited {
	font-family: 'Droid Sans', sans-serif;
	color: #1b70ba;
	font-size: 1rem;
	line-height: 1.5rem;
	font-weight: normal;
}

#alerts_module.homepage_contentbox.list_alerts .date {
	font-size: 0.8rem;
	font-weight: bold;
}

/* events */
#events_module.homepage_contentbox {
	margin-top: -20px;
}

#events_module.homepage_contentbox .event_list {
	margin-bottom: 0.75rem;
	padding-bottom: 0.5rem;
	border-bottom: 1px solid rgba(111, 124, 136, 0.15);
	display: inline-block;
	width: 100%;
}

#events_module.homepage_contentbox .event_list table tr td {
	display: inline-block;
	width: 100%;
	color: #727272;
	font-size: 0.875rem;
	line-height: 1.25rem;
}

#events_module.homepage_contentbox .event_list table tr td p {
	margin-top: 0px;
	margin-bottom: 0.5rem;
	color: #727272;
	font-size: 0.875rem;
	line-height: 1.25rem;
}

#events_module.homepage_contentbox .event_list table tr td.date {
	display: inline-block;
	padding-right: 0.25rem;
	padding-left: 0.25rem;
	background-color: #0c8563;
	color: #fff;
	font-size: 0.625rem;
	line-height: 1rem;
	font-weight: 700;
	text-transform: uppercase;
	width: auto;
}

#events_module.homepage_contentbox .event_list table tr td.title > div a {
	margin-top: 0rem;
	margin-bottom: 0.25rem;
	padding-top: 0;
	font-family: Rajdhani, sans-serif;
	font-size: 1.25rem;
	line-height: 1.25rem;
	font-weight: 600;
	display: inline-block;
	text-decoration: none;
	border-bottom: 1px solid transparent;
}

#events_module.homepage_contentbox .event_list table tr td.title > div a:hover {
	text-decoration: underline;
}

#events_module.homepage_contentbox .event_list table tr td .cms_description {
	margin-bottom: 0.5rem;
	color: #727272;
	font-size: 0.875rem;
	line-height: 1.25rem;
}

#events_module.homepage_contentbox .footer a {
	display: inline-block;
	margin-right: 1.5rem;
	padding: 0.25rem 1.5rem 0.125rem 0.125rem;
	clear: both;
	border-top: 1px solid #09b988;
	background-image: url('/20170713122722/assets/images/icon_arrow3_right_cblgray.svg');
	background-position: 98% 52%;
	background-size: 12px 10px;
	background-repeat: no-repeat;
	font-family: Rajdhani, sans-serif;
	color: #1b70ba;
	font-size: 1rem;
	font-weight: 600;
	text-transform: uppercase;
	text-decoration: none;
}

#events_module.homepage_contentbox .footer a:hover {
	background-image: url('/20170713122722/assets/images/icon_arrow3_right_cblgray_dark.svg');
	color: #0c8563;
	cursor: pointer;
	text-decoration: underline;
}

/* files */
.homeboxes .collection.files-collection {
	text-align: left;
}

.homeboxes .collection.files-collection .node.files-node {
	margin: 0px;
	padding: 0px;
}

/* forums */
#forums_module.homepage_contentbox h3 {
	font-size: 1.1rem;
	line-height: 1.5rem;
	margin: 0px;
}

#forums_module.homepage_contentbox .cms_list_item {
	margin-bottom: 17px;
	text-align: left;
}

/* news */
#news_module.homepage_contentbox {
	text-align: left;
}

#news_module.homepage_contentbox h3 {
	font-size: 1.1rem;
	line-height: 1.3rem;
	margin: 0px 0px 5px 0px;
}

#news_module.homepage_contentbox .cms_list_item {
	margin-bottom: 23px;
}

/* newsletter */


/* partners */
#partners_module.homepage-content-box.partners-carousel .cycle-slideshow-carousel .partner-carousel-slide {
	padding-right: 20px;
	padding-left: 20px;
	border-right: 1px solid rgba(111, 124, 136, 0.07);
}

#partners_module.homepage-content-box.partners-carousel .cycle-slideshow-carousel .partner-carousel-link {
	height: 100%;
	display: flex;
	align-items: center;
}

#partners_module.homepage-content-box.partners-carousel .cycle-slideshow-carousel .cycle-carousel-wrap {
	display: flex;
}

#partners_module.homepage-content-box.partners-carousel .partners-arrow-div.partners-cycle-carousel-previous,
#partners_module.homepage-content-box.partners-carousel .partners-arrow-div.partners-cycle-carousel-next {
	position: absolute;
	width: 2rem;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	margin: auto;
	cursor: pointer;
	-webkit-transition: background-color 200ms ease, color 200ms ease;
	transition: background-color 200ms ease, color 200ms ease;
	color: #25cb72;
	font-size: 1.25rem;
}

#partners_module.homepage-content-box.partners-carousel .partners-arrow-div.partners-cycle-carousel-previous:hover,
#partners_module.homepage-content-box.partners-carousel .partners-arrow-div.partners-cycle-carousel-next:hover {
	background: rgba(37, 50, 66, 0.5);
	color: #fff;
	font-family: inherit;
}

#partners_module.homepage-content-box.partners-carousel .partners-arrow-div.partners-cycle-carousel-previous {
	right: auto;
	border-right: 1px solid rgba(111, 124, 136, 0.07);
}

#partners_module.homepage-content-box.partners-carousel .partners-arrow-div.partners-cycle-carousel-next {
	left: auto;
}

#partners_module.homepage-content-box.partners-carousel .arrow-icon-left,
#partners_module.homepage-content-box.partners-carousel .arrow-icon-right {
	font-family: 'webflow-icons' !important;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	margin: auto;
	width: 1em;
	height: 1em;
	position: absolute;
	border: 0px;
	transform: none;
}

#partners_module.homepage-content-box.partners-carousel .arrow-icon-left:before {
	content: "\e601";
}

#partners_module.homepage-content-box.partners-carousel .arrow-icon-right:before {
	content: "\e600";
}

/* posts */
.homeboxes .collection.posts-collection {
	text-align: left;
}

.homeboxes .collection.posts-collection .node.posts-node {
	padding: 0px;
	margin: 0px 0px 17px 0px;
}

/* rss */
#feeds_module.homepage_contentbox {
	text-align: left;
}

#feeds_module.homepage_contentbox .item {
	background-position: 0px 5px;
}

/* testimonials */
#testimonials_module_contentbox.contentbox_item .author-name,
#testimonials_module_contentbox.contentbox_item .company {
	font-weight: bold;
	font-size: 0.9rem;
}

#testimonials_module_contentbox.contentbox_item.withThumbnail .image-box {
	margin-bottom: 5px;
}

/* videos */
.homeboxes .homepage-featured-video iframe {
	max-width: 100%;
	width: 100%;
}

/* classifieds */
.homeboxes .collection.classifieds-collection {
	text-align: left;
}

.homeboxes .collection.classifieds-collection .node.classifieds-node {
	margin: 0px 0px 17px 0px;
	padding: 0px;
}

/* ideas */
.homeboxes .collection.ideas-collection {
	text-align: left;
}

.homeboxes .collection.ideas-collection .node.ideas-node {
	margin: 0px 0px 17px 0px;
	padding: 0px;
}

/* kudos */
.homeboxes .collection.kudos-collection {
	text-align: left;
}

.homeboxes .collection.kudos-collection .node.kudos-node {
	margin: 0px 0px 17px 0px;
	padding: 0px;
}

/* submission form */
#submissionforms_module.homepage_contentbox table {
	width: auto;
}

#submissionforms_module.homepage_contentbox input[type="text"],
#submissionforms_module.homepage_contentbox input[type="tel"],
#submissionforms_module.homepage_contentbox input[type="email"],
#submissionforms_module.homepage_contentbox select,
#submissionforms_module.homepage_contentbox textarea {
	width: 100%;
	max-width: 100%;
	padding: 8px 12px;
	vertical-align: middle;
	background-color: #ffffff;
	border: 1px solid #ccc;
	font-size: 0.9rem;
	margin-bottom: 10px;
}

#submissionforms_module.homepage_contentbox textarea {
	width: 100% !important;
}

#submissionforms_module.homepage_contentbox .cms_field {
	padding-bottom: 5px;
}

#submissionforms_module.homepage_contentbox input[type="checkbox"],
#submissionforms_module.homepage_contentbox input[type="radio"] {
	margin-right: 3px;
}

#submissionforms_module.homepage_contentbox .sigWrapper .pad {
	max-width: 100%;
}

/* ecommerce  */
#products_module.homepage_contentbox {
	text-align: left;
	display: inline-block;
	width: 100%;
}

#products_module.homepage_contentbox.displaymode_category_list .links {
	border-bottom: 1px solid #e9ece3;
	margin-bottom: 20px;
	padding-bottom: 10px;
}

#products_module.homepage_contentbox.displaymode_list .cms_list_item_grid_container {
	width: 100%;
	display: block;
	border-bottom: 1px solid #e9ece3;
	margin-bottom: 20px;
	padding-bottom: 20px;
}

#products_module.homepage_contentbox.displaymode_list .cms_list_item_grid_container .name {
	margin-top: 5px;
}

#products_module.featured_list .cycle-slide > div {
	padding-top: 0px !important;
}

#products_module.featured_list .cms-slideshow h3 {
	font-size: 1.1rem;
	line-height: 1.4rem;
}

#products_module.homepage_contentbox.displaymode_category_list .cms_image {
	margin-bottom: 5px;
}
