/********* Use relative rather than absolute units in CSS property values ******/
/*******************************************************************************/
/* /core/libraries/select2/assets/select2.css */
.select2-container .select2-choice abbr {
	font-size: 0.0625rem;
}

/* /core/public/shared/assets/js/jquery/fullcalendar/3.2.0/fullcalendar.css */
.fc-time-grid-event.fc-allow-mouse-resize .fc-resizer {
	font-size: 0.6875rem;
}


/**************** Difficult to see the dotted link focus outline ***************/
/*******************************************************************************/
/* /core/libraries/slickSlider/assets/1.4.1/slick.css */
/* /core/libraries/slickSlider/assets/1.6.1/slick.css */
.slick-list:focus {
	outline: dotted;
}



/****** Ensure that foreground and background colors have enough contrast ******/
/*******************************************************************************/
/* /core/libraries/tablesaw/assets/2.0.3/tablesaw.bare.css */
.tablesaw-enhanced .tablesaw-bar .btn:hover,
.tablesaw-enhanced .tablesaw-bar .btn:focus {
	color: #1773ba;
	background-color: #fff;
	font-family: inherit;
}

/* /core/public/shared/assets/js/jquery/fullcalendar/3.2.0/fullcalendar.css */
.fc-basic-view .fc-day-top .fc-week-number {
	background-color: #ffffff;
	color: #636363;
	font-family: inherit;
}


/*** Removing the underline from links makes it hard for color-blind users to see them ***/
/****************************************************************************************/
/* /core/public/shared/assets/js/jquery/fullcalendar/3.2.0/fullcalendar.css */
.fc-list-item-title a {
	text-decoration: underline;
}


/*** Ensure that all information conveyed with color is also available without color ****/
/****************************************************************************************/
/* /core/public/shared/assets/js/jquery/fullcalendar/3.2.0/fullcalendar.css */
.fc-unthemed .fc-popover {
	background-color: #fff;
	font-weight: 600;
}

.fc-unthemed .fc-popover .fc-header .fc-close {
	color: #666;
	font-weight: 600;
}

.fc-state-hover,
.fc-state-down,
.fc-state-active,
.fc-state-disabled {
	color: #333333;
	background-color: #e6e6e6;
	font-weight: 600;
}

.fc-unthemed .fc-list-empty {
	background-color: #eee;
	font-weight: 600;
}
